body {
  margin: 0;
  font-family: 'Roboto Mono';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #262626;
}

* {
  box-sizing: border-box;
}

select {
  font-family: 'Roboto Mono';
  font-size: 16px;
  appearance: none;
  border: 0;
  background-color: white;
  padding: 10px;
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.2);
  margin: 5px 0;
  border-radius: 10px;
}

input[type='text'] {
  font-family: 'Roboto Mono';
  display: block;
  font-size: 16px;
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  background-color: white;
  border: 0;
  border-radius: 4px;
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.2);
}
